import $ from 'jquery'
import $Form from '../common/forms'
import $Tooltips from '../common/tooltips'
import client from '../common/client'

$(async function () {
  var $paymentForm = $('#booking-payment-form');

  if ($paymentForm.length) {
    var $paymentMethodRadios = $('input[name=payment_method_id]'),
        $paymentOptionsForm = $('#booking-payment-form'),
        $newCardInput = $('#new-card-radio'),
        $newCardFields = $('.new-card-fields'),
        $hiddenCardForm = $('.hidden-card-form'),
        $cardNumberContainer = $('#card-number-element'),
        $cardExpiryContainer = $('#card-expiry-element'),
        $cardCvcContainer = $('#card-cvc-element'),
        $cardPaymentOptions = $('#card-payment-options'),
        $button = $('#booking-payment-form input[type="submit"]'),
        _stripePublishableKey = $('meta[name=stripe-publishable-key]').attr('content'),
        stripe = Stripe(_stripePublishableKey),
        elements = stripe.elements({
          locale: 'en'
        }),
        style = {
          base: {
            fontFamily: '"Proxima Nova", "Helvetica", Arial, sans-serif',
            lineHeight: '50px',
            fontSize: '16px',
            paddingBottom: '7px',
            '::placeholder': {
              color: '#a4a4a4'
            }
          },
          invalid: {
            color: 'inherit'
          }
        },
        cardNumber = elements.create('cardNumber', {
          style: style,
          placeholder: 'e.g. 1234 1234 1234 1234'
        }),
        cardExpiry = elements.create('cardExpiry', {style: style}),
        cardCvc = elements.create('cardCvc', {style: style});

    $Tooltips.watchTooltips($('#card-cvc-help'));

    $('.payment-request-radio').on('change',function() {
      $('#payment-request-form-fields input').prop('disabled', false);
      $('#card-payment-options input').prop('disabled', true);
      $button.prop('disabled', false);
      $cardPaymentOptions.slideUp();
    });
    $('.js-payment-option-bnpl').on('change', function () {
      $('#payment-request-form-fields input').prop('disabled', true);
      $('#card-payment-options input').prop('disabled', true);
      $button.prop('disabled', false);
      $cardPaymentOptions.slideUp();
    });
    $('.js-payment-option-through-platform').on('change', function () {
      $('#payment-request-form-fields input').prop('disabled', true);
      $('#card-payment-options input').prop('disabled', false);
      $button.prop('disabled', false);
      $cardPaymentOptions.slideDown();
    });

    function stripeResponseHandler(result, form) {
      if (result.error) {
        $Form.showGeneralError(form, 'stripe', result.error.message);
        return $Form.renableButton(form.find('input[type=submit]'));
      } else {
        if (result.setupIntent) {
          form.find('input#payment_method_id').val(result.setupIntent.payment_method);
          $newCardInput.val(result.setupIntent.payment_method);
        }
        $Form.postAjax(form, form.find('input[type=submit]'));
        return true;
      }
    }

    if ($newCardFields.length) {
      cardNumber.mount($cardNumberContainer[0]);
      cardExpiry.mount($cardExpiryContainer[0]);
      cardCvc.mount($cardCvcContainer[0]);
    }

    function handleElementChange(elementContainer, state) {
      $Form.removeError(elementContainer, 'stripe', $Form.getErrorID(elementContainer));
      elementContainer.data('stripeChangeState', state);
    }

    cardNumber.on('change', function (state) {
      handleElementChange($cardNumberContainer, state);
    });
    cardExpiry.on('change', function (state) {
      handleElementChange($cardExpiryContainer, state);
    });
    cardCvc.on('change', function (state) {
      handleElementChange($cardCvcContainer, state);
    });

    if ($hiddenCardForm.length) {
      $paymentMethodRadios.on('change', function () {
        if ($newCardInput.is(':checked')) {
          $hiddenCardForm.slideDown();
        } else {
          $hiddenCardForm.slideUp();
        }
      });
    }

    function isEnteringNewCard() {
      return !userSelectedApplePay() && !userSelectedExistingCard() && !userSelectedBumperBNPL();
    }

    function userSelectedApplePay() {
      return $('#payment-request-apple').is(':checked');
    }

    function userSelectedBumperBNPL() {
      return $('#payment-request-bumper-bnpl').is(':checked');
    }

    function userSelectedExistingCard() {
      const checkedRadio = $paymentMethodRadios.filter(':checked');
      return checkedRadio.length && checkedRadio.val() !== '-1';
    }

    $paymentForm.each(function (i, form) {
      $(form).on('custom-validation', function (e, noOfErrors) {
        custom_validation(e, noOfErrors);
      });
      $(form).on('submit', paymentFormSubmit);
    });

    function custom_validation(e, noOfErrors) {
      var errors = noOfErrors,
          containers = [$cardNumberContainer, $cardExpiryContainer, $cardCvcContainer],
          form = $(e.currentTarget);

      if (isEnteringNewCard(form)) {
        containers.forEach(function (container) {
          var state = container.data('stripeChangeState');
          if (!state || state.empty) {
            errors++;
            $Form.showError(form, container, 'stripe', container);
          } else if (state.error) {
            errors++;
            $Form.showError(form, container, 'stripe', container, state.error.message);
          }
        });
      }

      $paymentForm.trigger('custom-validation-callback', [errors]);
    }

    function paymentFormSubmit(e) {
      e.preventDefault();
      e.stopPropagation();

      var form = $(this);

      // If the event is not triggered, then submit hasn't been called by $Form.validate
      if (!e.isTrigger) {
        $Form.validate(form);
        return;
      }

      if (userSelectedApplePay()) {
        paymentRequest.show();
      } else if (isEnteringNewCard()) {
        if (typeof stripe !== 'undefined') {
          if ($newCardInput.length && $newCardInput.val() !== '-1') {
            $Form.postAjax(form, form.find('input[type=submit]'));
          } else {
            stripe.confirmCardSetup(
                form.find('input[type=submit]').data('secret'),
                {
                  payment_method: {
                    card: cardNumber,
                    billing_details: {
                      name: form.find('#card-name').val()
                    }
                  }
                }
            ).then(function (result) {
              stripeResponseHandler(result, form);
            });
          }
        } else {
          $Form.showGeneralError(form, 'stripe', 'No secure connection');
        }
        return false;
      } else {
        $Form.postAjax(form, form.find('input[type=submit]'));
        return true;
      }
    }

    var paymentRequest = stripe.paymentRequest({
      country: 'GB',
      currency: 'gbp',
      total: {
        label: 'Quote total amount',
        amount: parseInt($('meta[name=authorization-total]').attr('content')),
        pending: true
      },
      requestPayerName: true
    });

    const PaymentOptions = Object.freeze({
      CARD:   Symbol("card"),
      APPLE_PAY:  Symbol("apple-pay"),
      BUMPER_BNPL: Symbol("bumper-bnpl")
    });

    const availablePaymentOptions = [PaymentOptions.CARD];

    const hasApplePay = async () =>  {
      if (!client.isMac()) {
        const result = await paymentRequest.canMakePayment()
        if (result && result.applePay) {
          return true;
        }
      }
      return false
    }

    const hasBnpl = () => {
      return $('input[name=bnpl_enabled]').val() === 'true';
    }

    if(await hasApplePay()) availablePaymentOptions.push(PaymentOptions.APPLE_PAY);
    if(hasBnpl()) availablePaymentOptions.push(PaymentOptions.BUMPER_BNPL);

    if (availablePaymentOptions.length > 1) {
      $button.prop('disabled', true);
      $('#card-payment-options').hide();
      $('.payment-options').removeClass('hidden');
    }

    if(availablePaymentOptions.includes(PaymentOptions.CARD)) {
      $('.payment-request-options .card-payment').removeClass('hidden');
    }

    if(availablePaymentOptions.includes(PaymentOptions.APPLE_PAY)) {
      $('.payment-request-options .apple-pay').removeClass('hidden');
    }

    if(availablePaymentOptions.includes(PaymentOptions.BUMPER_BNPL)) {
      $('.payment-request-options .bumper-bnpl').removeClass('hidden');
    }

    paymentRequest.on('paymentmethod', function (ev) {
      stripe.confirmCardSetup(
          $paymentOptionsForm.find('input[type=submit]').data('secret'),
          {payment_method: ev.paymentMethod.id}
      ).then(function (result) {
        stripeResponseHandler(result, $paymentOptionsForm);
      });
    });

    paymentRequest.on('cancel', function () {
      $Form.renableButton($button);
    });
  }
});
