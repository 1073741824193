import $ from 'jquery'
import $Translate from '../i18n/translations'
import $Scroll from './smooth-scrolling'

// Handle basic form validation
const $Form = {

  // Common
  getFieldType: function (field) {
    var type = field.attr('type'),
        dataType = field.attr('data-type');
    type = (typeof dataType != 'undefined' ? dataType : typeof type != 'undefined' ? type : field[0].nodeName);

    if (field.prop('readonly')) {
      type = 'readonly';
    }

    return type.toLowerCase();
  },


  // Watch any forms on the page; stop them submitting by default, and instead run them through the
  // validate method
  lastKey: [],

  setUpWatch: function () {
    var forms = $('form');
    if (forms.length == 0) {
      return false;
    }

    forms.each(function () {
      $Form.watchForm($(this));
    });
  },

  watchForm: function (form) {
    // TODO: this check should be removed when all forms have been changed to support FE validation
    // better than 'Please enter your mechanic_review[comment].'
    // Skips the FE validation if the form hasn't explicitly been set-up to use it
    if (typeof form.attr('data-js-errors') == 'undefined') {
      return false;
    }

    form.on('click keydown', 'input[type="submit"], button', function (e) {
      e.preventDefault();
      e.stopPropagation();

      $Form.validate(form);
    })
    .on('keydown', function (e) {
      var keyCode = e.keyCode;
      if (keyCode === 13) {

        var id = (typeof form.attr('id') != 'undefined' ? form.attr('id') : form.attr('action').replace(/\//g, '_'));
        // If the user is on a textarea, or if the last key hit was up or down (likely the user
        // navigating through autocomplete options), hitting enter shouldn't submit the form
        if (e.target.nodeName.toLowerCase() != 'textarea' && ($Form.lastKey[id] == 'undefined' || ($Form.lastKey[id] != 38 && $Form.lastKey[id] != 40))) {
          e.preventDefault();
          e.stopPropagation();

          $Form.validate(form);
        }

      }

      $Form.lastKey[id] = keyCode;
    });
  },


  // Get form bindings
  formBindings: function (form) {
    return $._data(form[0], 'events');
  },

  // If the user has selected an option which makes extra fields become required,
  // e.g. selecting 'use a different card' on the payment page, add them to the required check
  activeOptionallyRequiredFields: function (form) {
    var requiredFields,
        fieldsAddingRequireds = form.find('input[data-require-fields]:checked, input[type=text][data-require-fields][value!=""], input[type=password][data-require-fields][value!=""], input[type=hidden][data-require-fields][value!=""]').filter(':enabled');

    if (fieldsAddingRequireds.length) {
      var selectors = [];
      fieldsAddingRequireds.each(function () {
        var input = $(this),
            fields = input.attr('data-require-fields').replace(/ /g, '').split(',');

        for (var i = 0, noOfFields = fields.length; i < noOfFields; i++) {
          selectors.push('#' + fields[i]);
        }

      });
      requiredFields = $(selectors.join(', '));
    }
    return requiredFields;
  },

  validateRequiredField: function (form, input) {
    var val = input.val(),
        name = input.attr('name'),
      type = $Form.getFieldType(input),
      error_count = 0;

    // If the field is a checkbox or radio, check if it's selected instead of checking its value
    if (type == 'radio' || type == 'checkbox') {
      if ($('input[name="' + name + '"]:checked').length == 0) {
        $Form.showError(form, input, type, name);
        error_count++;
      }
    }

    // Otherwise, look at value
    else {

      // If the field isn't empty, send it on to be validated by more specific logic,
      // e.g. to ensure email addresses are valid, etc.
      if (val.trim().length > 0) {
        error_count += $Form.validateFieldTypes(form, input, val, type, name);
      }


      // Otherwise, return an error
      else {
        $Form.showError(form, input, type, name);
        error_count++;
      }

    }
    return error_count;
  },

  validateConditionallyRequiredField: function (form, input) {
    // i.e. cases where one or another input needs to be selected, but not both
    var fields = input.attr('data-conditionally-required').replace(/ /g, '').split(','),
        noOfCheckedFields = 0,
        selectors = [],
        error_count = 0;

    for (var i = 0, noOfFields = fields.length; i < noOfFields; i++) {

      var field = $('input[name="' + fields[i] + '"]'),
          fieldType = $Form.getFieldType(field);

      if (fieldType == 'radio' || fieldType == 'checkbox' || fieldType == 'hidden') {
        if (field.is(':checked')) {
          noOfCheckedFields++;
        }
      } else {
        if ($.trim(field.val()).length > 0) {
          noOfCheckedFields++;
        }
      }

      selectors.push('input[name="' + fields[i] + '"]');
    }

    if (noOfCheckedFields == 0) {
      $Form.showError(form, input, $Form.getFieldType(input), input.attr('name'), null, $(selectors.join(', ')));
      error_count++;
    }
    return error_count;
  },

  validateOptionalFields: function (form, input) {
    // Check any optional fields of specific types, e.g. phone numbers
    var val = input.val(),
        name = input.attr('name'),
        type = $Form.getFieldType(input),
        error_count = 0;

    // Only run the check if the input isn't empty - as these aren't required, we only want to
    // validate any values
    if (val.trim() != '') {
      error_count += $Form.validateFieldTypes(form, input, val, type, name);
    }
    return error_count;
  },

  // Handle basic form validation
  validate: function (form) {

    var button = form.find('input[type="submit"]:not([data-delete]), button'),
        events = $Form.formBindings(form),
        error_count = 0;

    $Form.disableButton(button);

    var optionallyRequiredFields = $Form.activeOptionallyRequiredFields(form);
    var alwaysRequiredFields = form.find('input[required], textarea[required], select[required]').filter(':enabled');
    var allRequiredFields = alwaysRequiredFields.add(optionallyRequiredFields);
    allRequiredFields.each(function () {
      error_count += $Form.validateRequiredField(form, $(this));
    });

    var conditionallyRequiredFieldList = form.find('input[data-conditionally-required]')
    conditionallyRequiredFieldList.each(function () {
      error_count += $Form.validateConditionallyRequiredField(form, $(this));
    });

    var optionalFields = form.find('input:not([required]):not([data-conditionally-required])')
    optionalFields.each(function () {
      error_count += $Form.validateOptionalFields(form, $(this));
    });

    // Check any fields with a min or max attribute
    error_count += $Form.handleMinMaxValues(form, form.find('input[min], input[max]'), true, 'min', 'Number too small', 'max', 'Number too big');

    // Check any fields with a minimum or maximum character setting
    error_count += $Form.handleMinMaxValues(form, form.find('input[minlength], input[maxlength], textarea[minlength], textarea[maxlength]'), false, 'minlength', 'Value too short', 'maxlength', 'Value too long');

    // Handle any form-specific validation (this will be defined in a separate file)
    if (events != null && events['custom-validation'] !== undefined) {

      // Unset any previously existing end checks
      form.off('custom-validation-callback')

          // Trigger the custom validation, and wait for it to trigger its response
          .on('custom-validation-callback', function (e, error_count) {
            $Form.endValidationChecks(form, error_count, button, events);
          })
          .trigger('custom-validation', [error_count]);
    }

    // Or else skip straight to the end validation checks
    else {
      $Form.endValidationChecks(form, error_count, button, events);
    }
  },


  // Handle the next steps after validation
  endValidationChecks: function (form, errors, button, events) {

    // If there were errors, ensure the form can be resubmitted
    if (errors > 0) {
      $Form.handleFormErrors(form, button);
    }


    // Otherwise, submit the form to the server
    else {
      $Form.submitForm(form, button, events);
    }

  },


  // Validate common field types
  validateFieldTypes: function (form, input, val, type, name) {
    let error_count = 0;
    switch (type) {
      case 'email':
        if (!$Form.validateEmail(form, input, val, type, name)) {
          error_count++;
        }
        break;

      case 'email-confirmation':
        if (!$Form.validateEmailConfirmation(form, input, val, type, name)) {
          error_count++;
        }
        break;

        // NB: 'tel' fields get used across the site as number fields, for the mobile keyboards
        // Fields that are meant for phone numbers are definitively marked as 'phone'
      case 'tel':
        if (!$Form.validateNumber(form, input, val, type, name)) {
          error_count++;
        }
        break;

      case 'phone':
        if (!$Form.validatePhoneNumber(form, input, val, type, name)) {
          error_count++;
        }
        break;

      case 'password':
        if (!$Form.validatePassword(form, input, val, type, name)) {
          error_count++;
        }
        break;

      case 'password-confirmation':
        if (!$Form.validatePasswordConfirmation(form, input, val, type, name)) {
          error_count++;
        }
        break;

      case 'credit-card':
        if (!$Form.validateCreditCard(form, input, val, type, name)) {
          error_count++;
        }
        break;

      case 'vrm':
        if (!$Form.validateVRM(form, input, val, type, name)) {
          error_count++;
        }
        break;

      case 'postcode':
        if (!$Form.validatePostcode(form, input, val, type, name)) {
          error_count++;
        }
        break;
    }

    return error_count;
  },


  // Validate
  handleMinMaxValues: function (form, inputs, isNumber, minAttr, minError, maxAttr, maxError) {
    var error_count = 0;

    form.find(inputs).each(function () {
      var input = $(this),
          val = (isNumber ? parseInt(input.val().trim()) : input.val()),
          name = input.attr('name'),
          type = $Form.getFieldType(input),
          min = (typeof input.attr(minAttr) != 'undefined' ? parseInt(input.attr(minAttr)) : false),
          max = (typeof input.attr(maxAttr) != 'undefined' ? parseInt(input.attr(maxAttr)) : false),
          valLength = (isNumber ? val.length : val.trim().length);

      // Only run the checks if the input isn't empty
      if (valLength != '') {

        if (min !== false && (isNumber ? val < min : valLength < min)) { // Ensure min isn't false to account for a 0 value
          $Form.showError(form, input, type, name, $Translate.putOut(minError, min));
          error_count++;
        }

        if (max && (isNumber ? val > max : valLength > max)) {
          $Form.showError(form, input, type, name, $Translate.putOut(maxError, max));
          error_count++;
        }
      }

    });

    return error_count;
  },


  // Handle the case when there are errors on the form
  handleFormErrors: function (form, button) {
    $Form.renableButton(button);
    form.addClass('form-has-errors');

    var shouldScroll = form.attr('data-scroll-on-error');
    if (typeof shouldScroll == 'undefined') {
      shouldScroll = true;
    } else {
      shouldScroll = shouldScroll == 'true';
    }
    if (shouldScroll) {
      const errorMessage = form.find('strong.error:first, .alert-error:last').first();
      if(errorMessage.length) {
        $Scroll.smoothScroll(errorMessage);
      }
    }
  },


  // Validate email addresses
  validateEmail: function (form, input, val, type, name) {
    if (/[^\s@]+@[^\s@]+\.[^\s@]+/.test(val) !== true) {
      $Form.showError(form, input, type, name, $Translate.putOut('Invalid email address'));
      return false;
    } else {
      return true;
    }
  },


  // Validate phone number
  validatePhoneNumber: function (form, input, val, type, name) {
    val = val.match(/\d/g);
    if (val == null) {
      $Form.showError(form, input, type, name, $Translate.putOut('Phone number should only use digits'));
      return false;
    } else if (val.length < 10) {
      $Form.showError(form, input, type, name, $Translate.putOut('Phone number too short'));
      return false;
    } else {
      return true;
    }
  },


  // Validate numbers
  validateNumber: function (form, input, val, type, name) {
    if (/^\d+$/.test(val) !== true) {
      $Form.showError(form, input, type, name, $Translate.putOut('Please enter digits only'));
      return false;
    } else {
      return true;
    }
  },


  // Validate passwords
  validatePassword: function (form, input, val, type, name) {
    var length = val.length;

    if (length < 6) {
      $Form.showError(form, input, type, name, $Translate.putOut('Password too short'));
      return false;
    } else if (length > 128) {
      $Form.showError(form, input, type, name, $Translate.putOut('Password too long'));
      return false;
    } else {
      return true;
    }
  },

  // Validate VRM
  validateVRM: function (form, input, val, type, name) {
    var vrmRegexp = /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/i;
    // VRM REGEXP SOURCE: https://gist.github.com/danielrbradley/7567269

    // whitespace is stripped from the VRM value to be validated, .e.g. "L2 CGJ" becomes "L2CGJ"
    val = val.replace(/\s+/g, '')

    if (vrmRegexp.test(val) !== true) {
      $Form.showError(form, input, type, name, $Translate.putOut('Please enter correct VRM number'));
      return false;
    } else {
      return true;
    }
  },

  validatePostcode: function (form, input, val, type, name) {
    var postcodeRegexp = /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9A-Z][A-Z]{2}$/i;

    if (postcodeRegexp.test(val) !== true) {
      $Form.showError(form, input, type, name, $Translate.putOut('Please enter your full postcode'));
      return false;
    } else {
      return true;
    }
  },

  validatePasswordConfirmation: function (form, input, val, type, name) {
    var password = form.find('input[type=password][name!="' + input.attr('name') + '"]');
    if (password.val() != input.val()) {
      $Form.showError(form, input, type, name, $Translate.putOut('Passwords don\'t match'));
      return false;
    } else {
      return true;
    }
  },

  validateEmailConfirmation: function (form, input, val, type, name) {
    const email = form.find('input[type=email][name!="' + input.attr('name') + '"]');
    if (email.val() !== input.val()) {
      $Form.showError(form, input, type, name, $Translate.putOut('Email addresses do not match'));
      return false;
    }
    return true;
  },


  // Validate credit cards
  validateCreditCard: function (form, input, val, type, name) {

    // Remove spaces and punctuation
    var validNumber = val.replace(/ /g, '').replace(/[\.,-\/#!$%\^&\*;:{}=\-_`~()]/g, '');


    // Return an error if the new number isn't only digits
    if (!(/^\d+$/.test(validNumber))) {
      $Form.showError(form, input, type, name, $Translate.putOut('Credit card should only use digits'));
      return false;
    }


    // Otherwise, use the Luhn algorithm to check the credit card number is valid
    // Build an array with the digits in the card number
    var digits = validNumber.split('');
    for (var i = 0; i < digits.length; i++) {
      digits[i] = parseInt(digits[i], 10);
    }

    // Run the Luhn algorithm on the array
    var sum = 0,
        alt = false;

    for (i = digits.length - 1; i >= 0; i--) {
      if (alt) {
        digits[i] *= 2;
        if (digits[i] > 9) {
          digits[i] -= 9;
        }
      }

      sum += digits[i];
      alt = !alt;
    }


    // Check the result
    if (sum % 10 == 0) {
      return true;
    } else {
      $Form.showError(form, input, type, name, $Translate.putOut('Credit card invalid'));
      return false;
    }

  },


  // Handle the button
  disableButton: function (button) {
    button.addClass('disabled').prop('disabled', true);
  },

  renableButton: function (button) {
    button.removeClass('disabled').prop('disabled', false);
  },


  // Show errors
  getErrorID: function (input) {
    var id = (typeof input.attr('id') != 'undefined' ? input.attr('id') : input.attr('name'));
    return 'error_' + id.replace(/[\:\[\]]/g, '');
  },

  showError: function (form, input, type, name, error, conditionallyRequiredFields, classes) {
    if (typeof conditionallyRequiredFields == 'undefined') {
      conditionallyRequiredFields = false;
    }
    if (typeof classes == 'undefined') {
      classes = false;
    }

    // Ensure there's not already an error showing for this field
    var errorID = $Form.getErrorID(input);

    if (form.find('strong.' + errorID).length) {
      return false;
    }


    // If the function hasn't specifically been passed an error message, work out what to show
    if (typeof error == 'undefined' || error == null) {

      // Check if there's a default error in the field attributes
      if (typeof input.attr('data-error') != 'undefined') {
        error = input.attr('data-error');

        if (typeof input.attr('data-error-class') != 'undefined') {
          classes = input.attr('data-error-class');
        }
      }


      // Otherwise, show a default 'please enter/select' message
      else {
        var action = (typeof input.attr('data-action') == 'undefined' ? 'enter' : input.attr('data-action')),
            value = (typeof input.attr('data-value') == 'undefined' ? name : input.attr('data-value'));

        error = $Translate.putOut((action == 'enter' ? 'Please enter your details' : 'Please select a value'), value);
      }
    }


    // Show the error
    // TODO: rework this when we refactor the form mark-up
    var innerWrap = (typeof input.attr('data-wrap') != 'undefined' ? input.attr('data-wrap') : false),
        errorMarkup = '<strong class="error ' + errorID + (classes ? ' ' + classes : '') + '" data-name="' + name + '">' +
            (innerWrap ? '<' + innerWrap + '>' : '') +
            $Translate.putOut(error) +
            (innerWrap ? '</' + innerWrap + '>' : '') +
            '</strong>',

        setListeners = false,
        question = input.closest('.question', input.parents('.question')),
        area = (question.length ? question : input.parents('dd').prevAll('dt:first'));

    if (area.length) {
      $(errorMarkup).prependTo(area);
      input.addClass('input-error');
      setListeners = true;
    } else if (input.hasClass('form-control')) {
      $(errorMarkup).insertBefore(input);
      input.addClass('input-error').parents('.form-group').addClass('has-error');
      setListeners = true;
    }


    if (setListeners) {
      if (conditionallyRequiredFields) {
        $Form.setErrorListeners(conditionallyRequiredFields, type, errorID);
      } else {

        // If we have a single input that's a radio or checkbox, ensure we're listening to all
        // the inputs with that name
        if (type == 'radio' || type == 'checkbox') {
          input = $('input[type="' + type + '"][name="' + input.attr('name') + '"]');
        }

        $Form.setErrorListeners(input, type, errorID);
      }
    }

  },


  // Show general error
  showGeneralError: function (form, id, text) {
    var errorID = 'error_' + id,
        errorDiv = form.children('div#' + errorID);

    if (errorDiv.length == 0) {
      var markUp = '<div id="' + errorID + '" class="alert alert-error input-error">' +
          '<p class="title">' + $Translate.putOut('Something went wrong') + '</p>' +
          '<p>' + $Translate.putOut(text) + '</p>' +
          '</div>';
      form.prepend(markUp);
    } else {
      errorDiv.children('p:not(.title)').html(text);
    }
  },


  // Listen for changes to fields with errors
  listenerTimeouts: [],


  setErrorListeners: function (input, type, error) {
    switch (type) {
      case 'radio':
      case 'checkbox':
      case 'select':
      case 'readonly':
      case 'file':
        input.on('change.error', function () {
          $Form.listenForErrors(input, type, error);
        });
        break;

      default:
        input.on('keyup.error', function (e) {

          // Remove the error on any key that isn't tab, shift, enter, or an arrow key
          if ($.inArray(e.keyCode, [9, 13, 16, 37, 39]) < 0) {
            $Form.listenForErrors(input, type, error);
          }
        });
        break;
    }
  },


  listenForErrors: function (input, type, error) {
    if (typeof $Form.listenerTimeouts[error] == 'undefined') {
      $Form.listenerTimeouts[error] = setTimeout(function () {
        $Form.removeError(input, type, error);
      }, 100);
    }
  },


  // Remove errors
  removeError: function (input, type, error) {
    clearTimeout($Form.listenerTimeouts[error]);
    delete $Form.listenerTimeouts[error];

    input.off('change.error').off('keyup.error').removeClass('input-error');
    $('strong.' + error).remove();

    // TODO: rework this when we refactor the form mark-up
    var parent = input.parents('.form-group');
    if (parent.length) {
      parent.removeClass('has-error');
    }


    // Check if we can remove the error class from the form
    var form = input.parents('form:first');
    if (form.find('.error').length == 0) {
      form.removeClass('form-has-errors');
    }
  },


  // Submit the form
  submitForm: function (form, button, events) {
    // Make sure the form doesn't have a custom submit (e.g. the payment form)
    // Trigger it if it does
    if (events != null && events['submit'] !== undefined) {
      form.trigger('submit');
    }


    // Otherwise, handle it here
    else {
      $Form.postAjax(form, button, events);
    }
  },

  postAjax: function (form, button, events) {
    if (typeof events == 'undefined') {
      events = $Form.formBindings(form);
    }


    // The form's as good as we can get it in the front-end; post it to the server.
    $.ajax({
      url: form.attr('action'),
      type: form.attr('method'),
      data: form.serialize(),

      success: function (data) {
        if (events != null && events['success'] !== undefined) {
          form.trigger('success', [data]);
        } else if (data['redirect']) {
          window.location = data['redirect'];
          return false;
        }

        $Form.renableButton(button);
      },

      error: function (raw_data) {
        const data = JSON.parse(raw_data.responseText);

        var errors = (data['errors'] ? JSON.parse(data['errors']) : data),
            classes = (data['classes'] ? JSON.parse(data['classes']) : false);

        $.each(errors, function (k, v) {
          var text = '';

          // If the response is just 'errors' with a message, put it out
          if (k == 0) {
            $Form.showGeneralError(form, k, v);
          }


          // Otherwise, work out where each message should sit
          else {

            if (Array.isArray(v)) {
              $.each(v, function (i, t) {
                if (text != '') {
                  text += '<br/>';
                }
                text += t;
              });
            } else {
              text = v;
            }


            // If a matching input exists on the form, add an error to it
            var selectors = (k.indexOf('[') >= 0 ? 'input[name="' + k + '"]:first, textarea[name="' + k + '"]:first, select[name="' + k + '"]:first' : '#' + k + ':first'),
                input = form.find(selectors);


            if (input.length) {
              var name = input.attr('name'),
                  type = $Form.getFieldType(input),
                  conditionallyRequired = input.attr('data-conditionally-required'),
                  conditionallyRequiredInputs = false;


              // If the input is conditionally required, make sure all the listeners are set up
              if (typeof conditionallyRequired != 'undefined') {
                var fields = conditionallyRequired.replace(/ /g, '').split(','),
                    otherInputs = [];

                for (var i = 0, noOfFields = fields.length; i < noOfFields; i++) {
                  otherInputs.push('input[name="' + fields[i] + '"]');
                }

                conditionallyRequiredInputs = $(otherInputs.join(', '));
              }


              // Put out error
              $Form.showError(form, input, type, name, text, conditionallyRequiredInputs, (classes && classes[k] ? classes[k] : false));

            }


            // Otherwise, add it to the top of the form
            else {
              $Form.showGeneralError(form, k, text);
            }

          }


        });
        if (raw_data.status == 406) {
          form.trigger('not_accepted', [data]);
        }
        $Form.handleFormErrors(form, button);
      }
    });


  }

};

export default $Form;
